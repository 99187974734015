import { graphql, useStaticQuery } from "gatsby";

// PRODUCTS
export const fetchAllProducts = () => {
  const data = useStaticQuery(graphql`
    {
      allWpSimpleProduct {
        totalCount
        nodes {
          name
          id
          slug
          databaseId
          image {
            sourceUrl
          }
          productTags {
            nodes {
              name
            }
          }
          productCategories {
            cat: nodes {
              name
              id
              slug
            }
          }

          ... on WpSimpleProduct {
            id
            name
            price
            regularPrice
            onSale
          }
        }
      }
    }
  `);

  return data.allWpSimpleProduct.nodes;
};
